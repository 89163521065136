@import '/src/sass/variables';

body {
  font-family: $font-family-content;
}

h1, h2, h3 {
  font-family: $font-family-heading;
}

p, div, button, input {
  font-family: $font-family-content;
}

h2 {
  font-weight: bold;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-dialog-container {
  background: white !important;
}

.mat-menu-content {
  background: white !important;
}
.mat-menu-item {
  background: white;
  &:hover {
    background: #EEE !important;
  }
}
