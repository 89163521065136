@use '@angular/material' as mat;
@import 'variables';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat.core($typography-config);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($color-theme);
@include mat.button-theme($color-theme);

body {
  background: linear-gradient(-45deg, transparentize($frog-blue, 0.3), transparentize($frog-green, 0.6), transparentize($frog-pink, 0.8), transparentize($frog-yellow, 0.8)) $color-background;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  margin: 0;
  font-family: 'Raleway', sans-serif;
  height: inherit;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


a {
  color: $color-text-primary-light;
  text-decoration: none;
}

a:hover {
  color: lighten($color-text-primary-light, 10);
  text-decoration: underline;
}

img {
  border: none;
}

mat-card {
  min-width: 300px;
}
