@use '@angular/material' as mat;
@use 'sass:map';

$font-family-heading: 'Ubuntu', sans-serif;
$font-family-content: 'Prompt', sans-serif;

$typography-config: mat.define-typography-config(
        $font-family:   $font-family-content,
        $display-4:     mat.define-typography-level(112px, 112px, 500, $font-family-heading, $letter-spacing: -0.05em), // Very-Big fat header
        $display-3:     mat.define-typography-level(56px, 56px, 500, $font-family-heading, $letter-spacing: -0.02em), // Big fat header
        $display-2:     mat.define-typography-level(45px, 48px, 700, $font-family-heading, $letter-spacing: -0.005em), // Less-Big fat header
        $display-1:     mat.define-typography-level(34px, 40px, 700, $font-family-heading), // Least-Big fat header
        $headline:      mat.define-typography-level(24px, 32px, 700, $font-family-heading), // H1
        $title:         mat.define-typography-level(20px, 32px, 500, $font-family-heading), // H2
        $subheading-2:  mat.define-typography-level(16px, 28px, 400, $font-family-heading), // H3
        $subheading-1:  mat.define-typography-level(15px, 24px, 400, $font-family-heading), // H4
        $body-2:        mat.define-typography-level(14px, 24px, 400), // Bolder text
        $body-1:        mat.define-typography-level(14px, 20px, 300), // Base text
        $caption:       mat.define-typography-level(12px, 20px, 200), // Smaller body text
        $button:        mat.define-typography-level(14px, 14px, 400), // Buttons
                // Line-height must be unit-less fraction of the font-size.
        $input:         mat.define-typography-level(inherit, 1.125, 300) // Inputs
);
