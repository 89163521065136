@import "_colors";
@import "_typography";

$color-white: white;
$color-text-primary: white;
$color-text-primary-light: #333333;
$color-text-primary-dark: #eeeeee;

$color-text-secondary: white;
$color-text-secondary-light: #555555;
$color-subtitle: rgba(0, 0, 0, 0.54);
$color-text-secondary-dark: #eeeeee;

$color-background: #a0c5e7;
$color-facebook: #3B5998;

$color-danger: #833843;

$size-all-without-footer: calc(100vh - 120px);
$size-screen-without-header: calc(100vh - 64px);
$size-screen-without-header-centered: calc(100vh - 128px);

$size-margin-card: 8px;

$size-avatar: 64px;
$size-avatar-border: 2px;

$size-player-avatar-s: 40px;
$size-player-avatar-m: 48px;
$size-player-avatar-l: 60px;

$size-form-min: 150px;
$size-form-max: 500px;

$size-leaderboard-title-s: 18px;
$size-leaderboard-text-s: 14px;
$size-leaderboard-subtext-s: 11px;
$size-leaderboard-line-spacing-s: 8px;
$size-leaderboard-side-widget-s: 300px;

$size-leaderboard-title-m: 28px;
$size-leaderboard-text-m: 18px;
$size-leaderboard-subtext-m: 18px;
$size-leaderboard-line-spacing-m: 12px;
$size-leaderboard-side-widget-m: 400px;

$size-leaderboard-title-l: 42px;
$size-leaderboard-text-l: 34px;
$size-leaderboard-subtext-l: 22px;
$size-leaderboard-line-spacing-l: 18px;
$size-leaderboard-side-widget-l: 540px;


$size-leaderboard-controls: 128px;
$size-leaderboard-social: 60px;
$size-leaderboard-header: 188px;
$size-leaderboard-header-w-padding: 198px;
$size-leaderboard-without-header-nor-padding: calc(100vh - 198px);
